require('./bootstrap');

import { EmojiButton } from '@joeattardi/emoji-button';


$(document).ready(function () {
    const picker = new EmojiButton();
    const triggerButton = document.getElementById("trigger");
    if(triggerButton){
        triggerButton.addEventListener('click', () => picker.togglePicker(trigger));
    }
    picker.on('emoji', selection => {
        var curPos = document.getElementById("textmessage").selectionStart;
        let x = $("#textmessage").val();
        let text_to_insert = selection.emoji;
        $("#textmessage").val(x.slice(0, curPos) + text_to_insert + x.slice(curPos));
    });


      window.addEventListener('send_file_link_to_chat', event => {
        var attachmentPath = event.detail.link;
        const message = {
            "message" : '<img src="'+attachmentPath+'"/>',
        }

        Livewire.emit('send_message', message , attachmentPath);
        // $("input[name='attachment_url']").val(attachmentPath);
        // var curPos = document.getElementById("textmessage").selectionStart;
        //         let x = $("#textmessage").val();
        //         let text_to_insert = attachmentPath;
        //         $("#textmessage").val(x.slice(0, curPos) + text_to_insert + x.slice(curPos));

                $('file#chatAttachment').find().val('');
    });


});

